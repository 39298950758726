import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import PersonalInfo from "../../../../components/BUAP/Student/StudentInfo/PersonalInfo";
import Cart from "../../../Registration/Cart/Cart";
import { connect } from "react-redux";
import axios from "../../../../axios/BUAP/registration";
import axiosCart from "../../../../axios/BUAP/cart";
import { Alert, Button } from "react-bootstrap";
import RegisteredCourses from "../../../Registration/RegisteredCourses";
import ParadigmIcon from "./ParadigmIcon";
import * as actionCreators from "../../../../store/actions/BUAP/BUAP";
import AddCourseStatus from "../../../../components/BUAP/Student/Paradigm/AddCourseStatus";
import PopupComponent from "../../../Registration/PopupComponent";
import ListOfSections from "../../../Registration/Cart/CartCourses/ListOfSections";
import GetToken from "../../../../Shared/GetToken";
import Blocked from "../Blocked";
import BUGroupedData from "../../../../components/Shared/Tables/BUGroupedData/BUGroupedData";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";
import ListOfCourses from "./ListOfCourses";

const regcolumns = [
  { name: "status", title: "Status", aligmentstyle: "left" },
  { name: "catalogKey", title: "Course catalog", aligmentstyle: "left" },
  { name: "title", title: "Title", aligmentstyle: "left" },
  { name: "courseType", title: "Course type", aligmentstyle: "left" },
  { name: "credits", title: "Credits", aligmentstyle: "left" },
  { name: "semester", title: "Semseter", aligmentstyle: "left" },
  { name: "level", title: "Level", aligmentstyle: "left" },
  { name: "add", title: "Add", aligmentstyle: "center" },
];

class Paradigm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ParadigmData: [
        {
          status: "",
          catalogKey: "",
          title: "",
          courseType: "",
          credits: "",
          prerequisite: "",
          relatedTo: "",
          offeredIn: "",
          semester: "",
          level: "",
          add: "",
          courseCode: "",
          groupType: 0,
        },
      ],
      selectedHeader: "",
      modalShow: false,
      group: false,
      selectedCourseOfferedSections: 0,
      minMax: [],
      regCoulum: regcolumns,
      updatedStatus: false,
      workingTerm: 0,
      groups: [],
      showParadigm: true,
      showinfo: false,
      waitData: true,
      allowedToShowStatusAndCart: false,
    };
    this.getOfferedCoursesHanlder = this.getOfferedCoursesHanlder.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.UpdateParadigmData = this.UpdateParadigmData.bind(this);
    this.printPage = this.printPage.bind(this);
  }

  getData = () => {
    axios
      .get(
        "StudentParadigm/" +
          this.props.usertype +
          "/" +
          this.props.studentdegree,
        {
          headers: { Authorization: GetToken("token") },
        }
      )
      .then((response) => {
        this.setState({
          workingTerm: response.data.paradigmFrontend.workingTerm,
        });
        this.UpdateParadigmData(response.data.paradigmFrontend.paradigmData);
        this.setState({ showParadigm: true });
        this.updateStatus();
      })
      .catch((error) => {
        this.setState({ showParadigm: false, waitData: false });
      });
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.usertype !== this.props.usertype) {
      this.getData();
    }
    if (
      this.props.updatecart === -1 ||
      prevProps.usertype !== this.props.usertype
    ) {
      this.props.setupdatecart(0);
    } else if (prevProps.updatecart !== this.props.updatecart) {
      //Update with preregistration
      if (
        this.props.allowtoreg === 1 &&
        (this.props.usertype === "0" || this.props.usertype === 0) &&
        !this.props.updatedStatus
      ) {
        //Prevent multiple update status. InPending needs one update
        // this.updateStatus();
        this.setState({ updatedStatus: true });
      }
    }

    if (
      prevProps.coursecart.courseCode !== this.props.coursecart.courseCode ||
      prevProps.coursecart.status !== this.props.coursecart.status
    ) {
      let objIndex = this.state.ParadigmData.findIndex(
        (obj) => obj.courseCode === this.props.coursecart.courseCode
      );

      if (objIndex !== -1) {
        let data = this.state.ParadigmData;
        if (data[objIndex].groupType === 1) {
          data[objIndex].status = (
            <ParadigmIcon status={this.props.coursecart.status} />
          );
          this.setState({ ParadigmData: data });
        }
      }
    }

    if (prevProps.upstatus !== this.props.upstatus) {
      this.updateStatus();
    }
  }

  getOfferedCoursesHanlder(add, catalogkey, courseCode) {
    if (add === 2) {
      alert("The course is offerd but you are not allow to register it!");
    } else {
      this.setState({
        modalShow: true,
        selectedHeader: catalogkey,
        selectedCourseOfferedSections: courseCode,
        group: add === 9,
      });
    }
  }

  updateStatus = () => {
    axiosCart
      .get(
        "MinMaxStatus/" +
          this.props.studentdegree +
          "/" +
          this.props.usertype +
          "/true",
        {
          headers: { Authorization: GetToken("token") },
        }
      )
      .then((response) => {
        if (response.data.status === "NoStatus") {
          this.props.setallowreg(false);
          this.setState({
            allowedToShowStatusAndCart: false,
            regCoulum: regcolumns.filter((el) => el.name !== "add"),
          });
        } else {
          this.props.setallowreg(response.data.allowToReg);
          this.setState({
            minMax: response.data,
            regCoulum:
              response.data.allowToReg !== 0 && response.data.allowToReg !== 14
                ? regcolumns
                : regcolumns.filter((el) => el.name !== "add"),
            allowedToShowStatusAndCart: true,
          });
        }
      });
  };

  UpdateParadigmData(data) {
    if (this.state.minMax.allowToReg !== 0) {
      data = data.map((course, index) => {
        return {
          status: <ParadigmIcon status={course.status} />,
          catalogKey: course.catalogKey,
          title: course.title,
          courseType: course.courseType,
          credits: course.credits,
          prerequisite: course.prerequisite,
          relatedTo: course.relatedTo,
          offeredIn: course.offeredIn,
          semester: course.semester,
          level: course.level,
          add: course.add ? (
            <Button
              variant="link"
              style={{ padding: 0, background: "none" }}
              onClick={() =>
                this.getOfferedCoursesHanlder(
                  course.add,
                  course.catalogKey,
                  course.courseCode
                )
              }
            >
              <AddCourseStatus status={course.add}></AddCourseStatus>
            </Button>
          ) : null,
          courseCode: course.courseCode,
          groupType: course.groupType,
        };
      });
    } else {
      data = data.map((course, index) => {
        return {
          status: <ParadigmIcon status={course.status} />,
          catalogKey: course.catalogKey,
          title: course.title,
          courseType: course.courseType,
          credits: course.credits,
          prerequisite: course.prerequisite,
          relatedTo: course.relatedTo,
          offeredIn: course.offeredIn,
          semester: course.semester,
          level: course.level,
          courseCode: course.courseCode,
          groupType: course.groupType,
        };
      });
    }

    //Gropus
    const maingroups = [
      ...new Set(
        data.map((course, index) => {
          return course.level;
        })
      ),
    ];
    const groupsdata = [
      ...new Set(
        data.map((course, index) => {
          return course.level + "|" + course.semester;
        })
      ),
    ];

    const allgroups = maingroups.concat(groupsdata);
    this.setState({
      ParadigmData: data,
      groups: allgroups,
      waitData: false,
    });
  }

  printPage() {
    window.print();
  }

  render() {
    return (
      <React.Fragment>
        {this.state.waitData ? (
          <SpinnerBootstrap />
        ) : (
          <React.Fragment>
            {this.state.showParadigm ? (
              <Container>
                <PersonalInfo
                  stuNo={this.props.stuno}
                  show={this.state.showinfo}
                />
                {this.state.allowedToShowStatusAndCart ? (
                  <React.Fragment>
                    {}
                    <RegisteredCourses
                      sem={this.state.workingTerm}
                      type={this.props.allowtoreg}
                    />
                    {this.props.usertype !== "0" &&
                    this.props.usertype !== 0 ? (
                      <Alert key="warning" variant="warning">
                        <a
                          href="https://docs.google.com/forms/d/e/1FAIpQLSfZxLqku-8rPunSqIM71_uKOkq1eI30fhJ-EtRYkLdeKgFjmg/viewform"
                          alt="Registration Special Request"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Registration Special Request طلب تسجيل خاص
                        </a>
                      </Alert>
                    ) : null}
                    {/* {this.props.usertype !== "0" &&
                    this.props.usertype !== 0 ? (
                      //&& this.state.workingTerm.toString().substring(4, 5) === "2"
                      <>
                        <Alert key="warning" variant="warning">
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfZxLqku-8rPunSqIM71_uKOkq1eI30fhJ-EtRYkLdeKgFjmg/viewform"
                            alt="Registration Special Request"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Registration Special Request طلب تسجيل خاص
                          </a>
                        </Alert>
                        {this.state.workingTerm.toString().substring(4, 5) ===
                        "2" ? (
                          <Alert key="warning" variant="warning">
                            <a
                              href="https://forms.gle/2kYXWwbEBHRBqrit6"
                              alt="Registration Special Request"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Request to be added to the Summer waitlist
                            </a>
                          </Alert>
                        ) : null}
                      </>
                    ) : null} */}
                    {/* {(this.props.usertype === "0" ||
                      this.props.usertype === 0) &&
                    this.state.workingTerm.toString().substring(4, 5) === "2" &&
                    this.state.minMax.maxCredits === 0 ? (
                      <Alert key="warning" variant="warning">
                        <a
                          href="https://forms.gle/2kYXWwbEBHRBqrit6"
                          alt="Registration Special Request"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Request to be added to the Summer waitlist
                        </a>
                      </Alert>
                    ) : null} */}
                    <Cart
                      status={this.state.minMax.status}
                      minLoad={this.state.minMax.minCredits}
                      maxLoad={this.state.minMax.maxCredits}
                      underProbation={this.state.minMax.onGPAProbation}
                    />
                    <br />
                  </React.Fragment>
                ) : null}
                <BUGroupedData
                  data={this.state.ParadigmData}
                  columns={this.state.regCoulum}
                ></BUGroupedData>
              </Container>
            ) : (
              <Blocked></Blocked>
            )}

            <PopupComponent
              show={this.state.modalShow}
              size={this.state.group ? "xl" : "l"}
              header={this.state.selectedHeader}
              onHide={() => this.setState({ modalShow: false })}
            >
              {this.state.group ? (
                <ListOfCourses
                  coursecode={this.state.selectedCourseOfferedSections}
                  group={true}
                  courseType={2}
                />
              ) : (
                <ListOfSections
                  coursecode={this.state.selectedCourseOfferedSections}
                  actualCredits={0}
                  updateCartHandler={() => this.updateCourseHandler}
                  buttonTitle="Add section"
                />
              )}
            </PopupComponent>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    stuno: state.stuno.studentno,
    usertype: state.utype.usertype,
    updatecart: state.ucart.updatecart,
    coursecart: state.ccart.coursecart,
    allowtoreg: state.allowreg.allowtoreg,
    upstatus: state.upstatus.updatestatus,
  };
};

//Redux
const mapDispatchToProps = (dispatch) => {
  return {
    setallowreg: (e) => dispatch(actionCreators.setallowreg(e)),
    setupdatecart: (e) => dispatch(actionCreators.setupdatecart(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Paradigm);
